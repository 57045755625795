import React from "react";
import { MDBNavbar, MDBNavbarBrand } from "mdbreact";
import logo from "../images/mbs_logo.jpeg";
import styled, { withTheme } from 'styled-components';

const NavbarWrapper = styled.div`
    .navbar-color {
        background-color: #ffffff !important;
    }

    nav .nav-link:hover {
        text-decoration: underline !important;
    }

    .navbar {
        box-shadow: none !important;
    }

    .logotype-navbar {
        background-color: #ffffff;
        display: flex;
        align-items: center;
        padding-left: 10px;
    }

    .logotype-image {
        width: 100%;
        max-width: 311px;
    }
`


const Navbar = () => {

    return (
        <NavbarWrapper>
            <MDBNavbar color="navbar-color px-5 py-3" dark expand="md">
                <MDBNavbarBrand>
                    <div className="logotype-navbar">
                        <img src={logo} alt="logotype" className="logotype-image"></img>
                    </div>
                </MDBNavbarBrand>
            </MDBNavbar>
        </NavbarWrapper>

    );
};


export default withTheme(Navbar);