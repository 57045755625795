import React, { useState, useEffect } from "react";
import { getArticles, getArticleDetails, getJournals } from "../../helpers/articles";
import { itemsPerPage, allFilterOption } from "../../constants";
import { getLastMonthArticles, getLastMonthTransactions } from "../../helpers/statistics";
import { MDBCol, MDBRow } from 'mdbreact';
import {
    BarChart,
    ArticleDetailsModal,
    Card,
    CustomTable
} from "pubtrack-frontend-library";
import { userSettings } from "../../user-settings";


const Articles = () => {
    const [selectedMessage, setSelectedMessage] = useState(null);
    const [articleDetails, setArticleDetails] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [transactions, setTransactions] = useState([]);
    const [articles, setArticles] = useState([]);

    const columns = React.useMemo(
        () => [
            {
                Header: 'manuscript ID',
                accessor: 'manuscript_id',
                sortable: true
            },
            {
                Header: 'DOI',
                accessor: 'doi',
                sortable: true
            },
            {
                Header: 'journal title',
                accessor: 'journal',
                sortable: true
            },
            {
                Header: 'manuscript title',
                accessor: 'manuscript_title',
                sortable: true
            },
            {
                Header: 'last event',
                accessor: 'lastevent',
                sortable: false
            }
        ], []
    );

    const addAllFilterOption = arr => {
        arr.unshift(allFilterOption);
        return arr;
    };

    const getArticlesData = ({ searchValue, offset, itemsPerPage, sortBy, sortDir, filterValue }) => {
        return getArticles(searchValue, offset, itemsPerPage, sortBy, sortDir, "published", filterValue)
            .then(res => ({ data: res.data.articles, total: res.data.total }))
    }

    const getFilterData = () => {
        return getJournals()
            .then(res => addAllFilterOption(res.data));
    }

    const onSelectRow = row => {
        const messageId = row && row.original ? row.original["id"] : null;
        setSelectedMessage(messageId);
    };

    useEffect(() => {
        getLastMonthTransactions()
            .then(res => setTransactions(res.data));

        getLastMonthArticles()
            .then(res => setArticles(res.data));
    }, []);

    useEffect(() => {
        if (selectedMessage) {
            getArticleDetails(selectedMessage)
                .then(res => setArticleDetails(res.data))
                .then(setIsOpen(true))
                .catch(error => console.log(error))
                .then(setSelectedMessage(null))
        }
    }, [selectedMessage]);

    return (
        <div className="container">
            <MDBRow className="mt-5">
                <MDBCol md='6' className="mb-5 mt-4">
                    <Card
                        title="Transactions per month:"
                        content={<BarChart data={transactions} xAxisKey="month" yAxisKey="count" />}
                    />
                </MDBCol>
                <MDBCol md='6' className="mb-5 mt-4">
                    <Card
                        title="New articles per month:"
                        content={<BarChart data={articles} xAxisKey="month" yAxisKey="count" />}
                    />
                </MDBCol>
            </MDBRow>

            <CustomTable
                columns={columns}
                itemsPerPage={itemsPerPage}
                getTableData={getArticlesData}
                getFilterData={getFilterData}
                onSelectRow={onSelectRow}
                sortByDefault={'created'}
                sortDirDefault={'desc'}
            />
            <ArticleDetailsModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                articleDetails={articleDetails}
                tabItems={userSettings.details}
            />
        </div>
    );
};


export default Articles;