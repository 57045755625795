import React from "react";
import Routes from "./routes";
import { Footer } from "pubtrack-frontend-library";
import { ThemeProvider } from 'styled-components'
import "./App.css";
import theme from "pubtrack-frontend-library/dist/theme";
import GlobalStyle from "pubtrack-frontend-library/dist/globalStyles";

const customTheme = {
  ...theme,
  primaryColor: '#008460',
  secondaryColor: '#006a82',

  tabItemColor: '#141a3b',

  accordionHeaderBackgroundColor: '#008460',

  modalHeaderBackgroundColor: '#008460',

  navbarBackgroundColor: '#008460',
  navbarButtonHoverColor: '#006a82',
  navbarTextTransform: 'uppercase',
  navbarFontSize: '14px',
  navbarFontWeight: 700,
  logoutButtonBackgroundColor: '#141a3b',

  paginationButtonBackgroundColor: '#008460',
  paginationButtonHoverBackgroundColor: '#006a82',

  tableHeaderBackgroundColor: '#008460',

  searchButtonBackgroundColor: '#006a82',

  primaryButtonBackgroundColor: '#141a3b',
  defaultButtonBackgroundColor: '#008460',
  secondaryButtonBackgroundColor: '#006a82',
  colorsPalette: [
        "#b3e0dc", "#81cdc6", "#4fb9af", "#28a99e", "#05998c", "#048c7f", "#037c6e", "#036c5f", "#025043"
    ]
}

const App = () => {
  return (
    <ThemeProvider theme={customTheme}>
      <GlobalStyle/>
      <Routes />
      <Footer />
    </ThemeProvider>
  );
};


export default App;