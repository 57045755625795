import { publisher_id } from "../constants";
import { axiosInstance } from "./axiosSetup";
import { handleErrors } from "pubtrack-frontend-library";


export async function getArticles(searchValue, offset, perPage, sortBy, sortDir, selection, filterValue = "") {
    return await axiosInstance
        .get(`misoc/tracker/articles?query=${searchValue}&startrow=${offset}&maxrows=${perPage}&orderby=${sortBy}&orderdir=${sortDir}&selection=${selection}&issn=${filterValue}`)
        .catch(handleErrors)
};

export async function getArticleDetails(selectedMessage) {
    return await axiosInstance
        .get(`misoc/tracker/article/${selectedMessage}`)
        .catch(handleErrors)
};

export async function getJournals() {
    return await axiosInstance
        .get(`/v1/journal/publisher/${publisher_id}`)
        .catch(handleErrors)
};
