import React, { useEffect, useState } from "react";
import { MDBCol, MDBRow } from 'mdbreact';
import { getInstitutions, getArticlesPerInstitution, getTransactionsPerInstitution } from "../../helpers/statistics";
import { Field, withFormik } from "formik";
import { getInstitutionDetails } from "../../helpers/institutions";
import {
    PieChart,
    Table,
    Card,
    Loader,
    ErrorMessage,
    AsyncInput,
    DataTable,
    loadRorOptions
} from "pubtrack-frontend-library";


const Reports = () => {
    const [institutions, setInstitutions] = useState([]);
    const [articlesPerInstitution, setArticlesPerInstitution] = useState([]);
    const [articlesPerInstitutionTableData, setArticlesPerInstitutionTableData] = useState({});
    const [transactionsPerInstitution, setTransactionsPerInstitution] = useState([]);
    const [transactionsPerInstitutionTableData, setTransactionsPerInstitutionTableData] = useState({});
    const [institutionDetails, setInstitutionDetails] = useState(null);
    const [sortBy, setSortBy] = useState("id");
    const [sortDir, setSortDir] = useState("asc");
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState(null);
    const [error, setError] = useState(false);

    const columns = React.useMemo(
        () => [
            {
                Header: 'Title',
                accessor: 'title',
                sortable: false
            },
            {
                Header: 'Deal',
                accessor: 'deal',
                sortable: false
            },
        ], []
    );

    useEffect(() => {
        getTransactionsPerInstitution()
            .then(res => setTransactionsPerInstitution(res.data));

        getArticlesPerInstitution()
            .then(res => setArticlesPerInstitution(res.data));

        getInstitutions()
            .then(res => setInstitutions(res.data));
    }, []);

    useEffect(() => {
        setArticlesPerInstitutionTableData({
            columns: [
                {
                    label: 'institution',
                    field: 'institution',
                    attributes: {
                        'aria-controls': 'DataTable',
                        'aria-label': 'institution',
                    },
                },
                {
                    label: 'count',
                    field: 'count',
                    attributes: {
                        'aria-controls': 'DataTable',
                        'aria-label': 'count',
                    },
                },
            ],
            rows:
                articlesPerInstitution.map(item => {
                    return (
                        {
                            "institution": item.institution,
                            "count": item.count,
                        }
                    )
                })
        });
    }, [articlesPerInstitution]);

    useEffect(() => {
        setTransactionsPerInstitutionTableData({
            columns: [
                {
                    label: 'institution',
                    field: 'institution',
                    attributes: {
                        'aria-controls': 'DataTable',
                        'aria-label': 'institution',
                    },
                },
                {
                    label: 'count',
                    field: 'count',
                    attributes: {
                        'aria-controls': 'DataTable',
                        'aria-label': 'count',
                    },
                },
            ],
            rows:
                transactionsPerInstitution.map(item => {
                    return (
                        {
                            "institution": item.institution,
                            "count": item.count,
                        }
                    )
                })
        });
    }, [transactionsPerInstitution]);

    useEffect(() => {
        if (value) {
            let isMounted = true;
            setLoading(true);

            getInstitutionDetails(value)
                .then(res => {
                    if (isMounted) {
                        setInstitutionDetails(res.data);
                        setLoading(false);
                    };
                })
                .catch(error => setError(true))
            return () => { isMounted = false };
        }
    }, [value]);


    return (
        <div className="container">
            <MDBRow className="mt-5 d-flex justify-content-center">
                <MDBCol md='6' className="my-4">
                    <Card
                        title="Most active institutions:"
                        content={<PieChart data={institutions} labelKey='institution' valueKey='count' />}
                    />
                </MDBCol>
                <MDBCol md='6' className="my-4">
                    <Card
                        title="Articles per institution:"
                        content={
                            <DataTable data={articlesPerInstitutionTableData} />
                        }
                    />
                </MDBCol>
            </MDBRow>

            <div className="my-5 pb-3">
                <h5 className="text-center my-4">Institution search:</h5>
                <div className="d-flex justify-content-end mb-4">
                    <Field component={AsyncInput} loadOptions={loadRorOptions} setValue={setValue} placeholder="Search" />
                </div>

                {
                    loading ?
                        <Loader />
                        :
                        error ?
                            <ErrorMessage />
                            :
                            !institutionDetails ?
                                null
                                :
                                <Table
                                    title="Institution articles:"
                                    data={institutionDetails}
                                    columns={columns}
                                    sortBy={sortBy}
                                    sortDir={sortDir}
                                    setSortBy={setSortBy}
                                    setSortDir={setSortDir}
                                    setSelectedMessage={() => { }}
                                />
                }
            </div>

            <div className="my-5">
                <DataTable title="Transactions per institution:" data={transactionsPerInstitutionTableData} />
            </div>
        </div>
    );
};


export default withFormik({})(Reports);